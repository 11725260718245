















































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {sessionService, userService} from "@/services";
import UserSession from "@/models/UserSession";
import {Role} from "@/models/enums/Role";
import {User} from "@/models/User";
import {ActionItem, sortAlphabetically} from "@/utils/table-utils";
import {EventBus} from "@/main";
import {
  compareRule,
  emailExistRule,
  emailRule,
  maxLengthRule,
  passwordRule,
  requiredRule, usernameExistRule,
  usernameRule,
  VForm
} from "@/utils/form-utils";
import {ChangePasswordRequest} from "@/models/request/ChangePasswordRequest";
import {Page} from "@/models/Page";
import {SortAndFilterOptions} from "@/models/SortAndFilterOptions";
import {WatchOptions} from "vue";
import {UserNotification} from "@/models/UserNotification";
import {UserNotificationType} from "@/models/UserNotificationType";
import {UpdateUserRequest} from "@/models/request/UpdateUserRequest";
import {CreateUserRequest} from "@/models/request/CreateUserRequest";

@Component({
  name: 'Users',
  components: {}
})
export default class UsersView extends Vue {

  $refs!: {
    createUserForm: VForm,
    editUserForm: VForm,
    changeUserPasswordForm: VForm
  }

  apiErrorMessages: any = {
    createUserRequest: {
      username: [],
      email: []
    },
    editUserRequest: {
      username: [],
      email: []
    }
  }

  loading: boolean = true;
  options: any = {};
  users: Page<User> = new Page();

  headers: Array<any> = [
    {text: 'ID', align: 'start', filterable: true, value: 'id', roles: [Role.ADMIN]},
    {
      text: 'Login',
      align: 'start',
      filterable: true,
      value: 'username',
      roles: [Role.ADMIN]
    },
    {
      text: 'Imię',
      align: 'start',
      filterable: true,
      value: 'name',
      roles: [Role.ADMIN]
    },
    {
      text: 'Nazwisko', align: 'start', filterable: false, value: 'surname', roles: [Role.ADMIN]
    },
    {
      text: 'Rola',
      align: 'start',
      filterable: true,
      value: 'role.label',
      sort: sortAlphabetically,
      roles: [Role.ADMIN]
    },
    {
      text: 'Adres email',
      align: 'start',
      filterable: true,
      value: 'email',
      sort: sortAlphabetically,
      roles: [Role.ADMIN]
    },
    {
      text: 'Aktywny',
      align: 'start',
      filterable: true,
      value: 'active',
      roles: [Role.ADMIN]
    },
    {text: '', value: "action", roles: [Role.ADMIN]}
  ];

  actionItems: Array<ActionItem> = [
    new ActionItem('Modyfikuj', this.openEditUserDialog),
    new ActionItem('Zmień hasło', this.openChangeUserPasswordDialog),
  ];

  session: UserSession = null;

  roles: Array<Role> = Role.list();
  createUserFormValid: boolean = false;
  createUserDialog: boolean = false;
  createUserServerError: boolean = false;
  createUserRequest: CreateUserRequest = {} as CreateUserRequest;
  createUserRules = {

    usernameRules: [
      requiredRule(),
      usernameRule(),
      maxLengthRule(100)
    ] as Array<Function>,

    nameRules: [
      requiredRule(),
      maxLengthRule(100)
    ] as Array<Function>,

    surnameRules: [
      requiredRule(),
      maxLengthRule(100)
    ] as Array<Function>,

    passwordRules: [
      requiredRule(),
      passwordRule()
    ] as Array<Function>,

    passwordRepeatRules: [
      requiredRule(),
      passwordRule()
    ] as Array<Function>,

    emailRules: [
      requiredRule(),
      emailRule(),
      maxLengthRule(100)
    ] as Array<Function>,

    roleRules: [
      requiredRule()
    ] as Array<Function>

  }

  editedUserRef: User = null;

  editUserFormValid: boolean = false;
  editUserDialog: boolean = false;
  editUserServerError: boolean = false;
  editUserRequest: UpdateUserRequest = {} as UpdateUserRequest;
  editUserRules = {

    usernameRules: [
      requiredRule(),
      usernameRule(),
      maxLengthRule(100)
    ] as Array<Function>,

    nameRules: [
      requiredRule(),
      maxLengthRule(100)
    ] as Array<Function>,

    surnameRules: [
      requiredRule(),
      maxLengthRule(100)
    ] as Array<Function>,

    emailRules: [
      requiredRule(),
      emailRule(),
      maxLengthRule(100)
    ] as Array<Function>,

    roleRules: [
      requiredRule()
    ] as Array<Function>,

  }

  changeUserPasswordFormValid: boolean = false;
  changeUserPasswordDialog: boolean = false;
  changeUserPasswordServerError: boolean = false;
  changeUserPasswordRequest: ChangePasswordRequest = {} as ChangePasswordRequest;
  changeUserPasswordRules = {

    passwordRules: [
      requiredRule()
    ] as Array<Function>,

    newPasswordRules: [
      requiredRule(),
      passwordRule()
    ] as Array<Function>,

    newPasswordRepeatRules: [
      requiredRule(),
      passwordRule()
    ] as Array<Function>

  }

  @Watch('createUserRequest', {deep: true} as WatchOptions)
  async createUserRequestApiValidation(): Promise<void> {

    this.apiErrorMessages.createUserRequest.email = [];
    let emailExistsResult: string | boolean = await emailExistRule()(this.createUserRequest.email);

    if (emailExistsResult !== true) {
      this.apiErrorMessages.createUserRequest.email.push(emailExistsResult);
    }

    this.apiErrorMessages.createUserRequest.username = [];
    let usernameExistsResult: string | boolean = await usernameExistRule('Login jest już zajęty')(this.createUserRequest.username);

    if (usernameExistsResult !== true) {
      this.apiErrorMessages.createUserRequest.username.push(usernameExistsResult);
    }

  }

  @Watch('editUserRequest', {deep: true} as WatchOptions)
  async editUserRequestApiValidation(): Promise<void> {

    if (this.editUserRequest.email !== this.editedUserRef.email) {

      let emailExistsResult: string | boolean = await emailExistRule()(this.editUserRequest.email);

      if (emailExistsResult !== true) {
        this.apiErrorMessages.editUserRequest.email.push(emailExistsResult);
      }

    }

    if (this.editUserRequest.username !== this.editedUserRef.username) {

      let usernameExistsResult: string | boolean = await usernameExistRule('Login jest już zajęty')(this.editUserRequest.username);

      if (usernameExistsResult !== true) {
        this.apiErrorMessages.editUserRequest.username.push(usernameExistsResult);
      }

    }

  }

  async created(): Promise<void> {

    this.createUserRules.passwordRules.push(compareRule(this, 'createUserRequest', 'password', 'passwordRepeat', 'Pole Powtórzone hasło musi być identyczne z polem Hasło'));
    this.createUserRules.passwordRepeatRules.push(compareRule(this, 'createUserRequest', 'passwordRepeat', 'password', 'Pole Hasło musi być identyczne z polem Powtórzone hasło'));
    this.changeUserPasswordRules.newPasswordRules.push(compareRule(this, 'changeUserPasswordRequest', 'newPassword', 'newPasswordRepeat', 'Pole Hasło musi być identyczne z polem Nowe powtórzone hasło'));
    this.changeUserPasswordRules.newPasswordRepeatRules.push(compareRule(this, 'changeUserPasswordRequest', 'newPasswordRepeat', 'newPassword', 'Pole Nowe powtórzone hasło musi być identyczne z polem Nowe hasło'));

    EventBus.$on('sessionChange', this.getSession)
    await this.getSession();
    await this.getUsers();
  }

  @Watch('options', {deep: true} as WatchOptions)
  async getUsers(): Promise<void> {

    //Skip when no options filled
    if (this.options['page'] === undefined) {
      return;
    }

    this.loading = true;
    this.users = await userService.list(SortAndFilterOptions.of(this.options));
    this.loading = false;

  }

  async getSession(): Promise<void> {
    this.session = await sessionService.getSession();
  }

  beforeDestroy(): void {
    EventBus.$off('sessionChange', this.getSession);
  }

  cancelCreateUser(): void {
    this.createUserDialog = false;
    this.createUserRequest = {} as CreateUserRequest;
  }

  async revalidateCreateUserForm(): Promise<void> {
    await this.$refs.createUserForm.validate();
  }

  async createUser(): Promise<void> {

    await this.$refs.createUserForm.validate();

    if (this.createUserFormValid) {
      this.createUserDialog = false;
      await userService.createUser(this.createUserRequest);
      this.createUserRequest = {} as CreateUserRequest;
      EventBus.$emit('send-notification', new UserNotification(UserNotificationType.SUCCESS, 'Utworzono użytkownika'));
      await this.getUsers();
    }

  }

  cancelEditUser(): void {
    this.editUserDialog = false;
    this.editUserRequest = {} as UpdateUserRequest;
  }

  cancelChangeUserPassword(): void {
    this.changeUserPasswordDialog = false;
    this.changeUserPasswordRequest = {} as ChangePasswordRequest;
  }

  async editUser(): Promise<void> {

    await this.$refs.editUserForm.validate();

    if (this.editUserFormValid) {
      this.editUserDialog = false;
      await userService.updateUser(this.editedUserRef.id, this.editUserRequest);
      this.editUserRequest = {} as UpdateUserRequest;
      EventBus.$emit('send-notification', new UserNotification(UserNotificationType.SUCCESS, 'Zmodyfikowano dane użytkownika'));
      await this.getUsers();
    }

  }

  openChangeUserPasswordDialog(user: User): void {
    this.changeUserPasswordDialog = true;
    this.changeUserPasswordRequest = {} as ChangePasswordRequest;
    this.editedUserRef = user;
  }

  openEditUserDialog(user: User): void {
    this.editUserDialog = true;
    this.editUserRequest = UpdateUserRequest.from(user);
    this.editedUserRef = user;
  }

  async revalidateChangeUserPasswordForm(): Promise<void> {
    await this.$refs.changeUserPasswordForm.validate();
  }

  async changeUserPassword(): Promise<void> {

    await this.$refs.changeUserPasswordForm.validate();

    if (this.changeUserPasswordFormValid) {
      this.changeUserPasswordDialog = false;
      await userService.changeUserPassword(this.editedUserRef.id, this.changeUserPasswordRequest);
      this.changeUserPasswordRequest = {} as ChangePasswordRequest;
      EventBus.$emit('send-notification', new UserNotification(UserNotificationType.SUCCESS, 'Hasło zostało zmienione'));
    }

  }

  userAction(user: User, actionItem: ActionItem): void {
    actionItem.action(user);
  }

}


