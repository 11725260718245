import {Role} from "@/models/enums/Role";
import {User} from "@/models/User";

export class UpdateUserRequest {

    username: string;
    name: string;
    surname: string;
    role: string;
    email: string;
    active: boolean;

    static from(user: User): UpdateUserRequest {

        let userRequest: UpdateUserRequest = new UpdateUserRequest();
        userRequest.username = user.username;
        userRequest.name = user.name;
        userRequest.surname = user.surname;
        userRequest.role = (<Role>user.role).name;
        userRequest.email = user.email;
        userRequest.active = user.active;

        return userRequest;

    }

}
